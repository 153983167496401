// Module
var code = `<span class="token keyword">import</span> Map <span class="token keyword">from</span> <span class="token string">"ol/Map.js"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> TileLayer <span class="token keyword">from</span> <span class="token string">"ol/layer/Tile.js"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> <span class="token constant">OSM</span> <span class="token keyword">from</span> <span class="token string">"ol/source/OSM"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> View <span class="token keyword">from</span> <span class="token string">"ol/View.js"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> ImageTileLayer <span class="token keyword">from</span> <span class="token string">"ol/layer/Tile"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> TileDebug <span class="token keyword">from</span> <span class="token string">"ol/source/TileDebug"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> <span class="token punctuation">{</span> fromLonLat <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">"ol/proj"</span><span class="token punctuation">;</span>

<span class="token keyword">import</span> <span class="token punctuation">{</span> importMBTiles<span class="token punctuation">,</span> MBTilesRasterSource <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">"ol-mbtiles"</span><span class="token punctuation">;</span>

<span class="token comment">// Raster MBTiles from</span>
<span class="token comment">// https://www.data.gouv.fr/en/datasets/pyramide-de-tuiles-depuis-la-bd-ortho-r/</span>
<span class="token comment">// 240MB original file</span>

<span class="token keyword">export</span> <span class="token keyword">default</span> <span class="token keyword">async</span> <span class="token keyword">function</span> <span class="token punctuation">(</span><span class="token punctuation">)</span> <span class="token punctuation">{</span>
  <span class="token keyword">return</span> <span class="token keyword">new</span> <span class="token class-name">Map</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
    target<span class="token operator">:</span> <span class="token string">"map"</span><span class="token punctuation">,</span>
    layers<span class="token operator">:</span> <span class="token punctuation">[</span>
      <span class="token keyword">new</span> <span class="token class-name">TileLayer</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
        zIndex<span class="token operator">:</span> <span class="token number">20</span><span class="token punctuation">,</span>
        source<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name">TileDebug</span><span class="token punctuation">(</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token keyword">new</span> <span class="token class-name">TileLayer</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
        zIndex<span class="token operator">:</span> <span class="token number">10</span><span class="token punctuation">,</span>
        opacity<span class="token operator">:</span> <span class="token number">0.4</span><span class="token punctuation">,</span>
        source<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name"><span class="token constant">OSM</span></span><span class="token punctuation">(</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token keyword">new</span> <span class="token class-name">ImageTileLayer</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
        zIndex<span class="token operator">:</span> <span class="token number">0</span><span class="token punctuation">,</span>
        source<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name">MBTilesRasterSource</span><span class="token punctuation">(</span>
          <span class="token keyword">await</span> <span class="token function">importMBTiles</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
            url<span class="token operator">:</span> <span class="token string">"https://velivole.b-cdn.net/tiles-RGR92UTM40S.mbtiles"</span><span class="token punctuation">,</span>
          <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
        <span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
    <span class="token punctuation">]</span><span class="token punctuation">,</span>
    view<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name">View</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
      center<span class="token operator">:</span> <span class="token function">fromLonLat</span><span class="token punctuation">(</span><span class="token punctuation">[</span><span class="token number">55.47437</span><span class="token punctuation">,</span> <span class="token operator">-</span><span class="token number">21.08468</span><span class="token punctuation">]</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      zoom<span class="token operator">:</span> <span class="token number">9</span><span class="token punctuation">,</span>
    <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
  <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">;</span>
<span class="token punctuation">}</span>
`;
// Exports
export default code;